import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import connect from '@vkontakte/vk-connect';
import ReactDOM from 'react-dom';
import Home from "./Home";




const App = () => {


	return (
        <div >

    </div>

		



	);
}


export default App;
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';

import App from './App';
import * as serviceWorker from './serviceWorker';
connect.send("VKWebAppInit", {});
connect.send('VKWebAppGetUserInfo', {});
const user = connect.send('VKWebAppGetUserInfo', {});
console.log(user);


async function fetchData() {
			const user = await connect.send('VKWebAppGetUserInfo');
			var name = user.first_name;
			var lastname = user.last_name;
			
			var id = user.id;
			var list = name + ', ' + lastname + ', '+ id;
			var sss = document.getElementById('one');
			sss.innerText = list;
            

		}


  fetchData();



//connect.send("VKWebAppGetPhoneNumber", {});
//connect.subscribe((e) => console.log(e));
// это работает!
//connect.subscribe((e) => console.log(e.detail.data.phone_number));
//connect.subscribe((e) => Saver(e.detail.data.phone_number));

function Saver(a) {
    var s = a;
   // alert('Phone: ' + s)
}




ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
